





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import report from '@/components/Report/ElementaryReport.vue'
import YearlyReport from '@/components/Report/YearlyReport.vue'
import { GradeController, TaskGradeController } from '@/services/request.service'
const operations: any = {
  high: function(periodId) {
    return GradeController.getUpperSchoolReport(periodId)
  },
  middle: function(periodId) {
    return GradeController.getMidSchoolReport(periodId)
  },
  elementary: function(periodId) {
    return GradeController.getGradeReportDetail(periodId)
  },
  middleYear: function(periodId) {
    return GradeController.getMidSchoolAnnualReport(periodId)
  },
  highYear: function(periodId) {
    return GradeController.getUpperSchoolAnnualReport(periodId)
  },
}

@Component({
  components: {
    report,
    YearlyReport,
  },
})
export default class ExportReport extends Vue {
  private data: any = {}
  private campusType: any = 'elementary'
  private reportType: any = 'term'
  private schoolYearType: any = '1101'
  private loaded: boolean = false

  private getDetail(periodId): void {
    const type = this.reportType === 'term' ? this.campusType : this.campusType + 'Year'

    operations[type](periodId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => console.log(err))
      .finally(() => (this.loaded = true))
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    const { periodId } = to.params
    this.campusType = to.params.campusType
    this.reportType = to.params.reportType
    this.schoolYearType = to.params.schoolYearType
    this.getDetail(periodId)
  }
}
