




import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import report from '@/components/Report/ElementaryReport.vue'
import YearlyReport from '@/components/Report/YearlyReport.vue'
import MonthlyReport from '@/components/Report/MonthlyReport.vue'
import { GradeController } from '@/services/request.service'

@Component({
  components: {
    MonthlyReport,
  },
})
export default class ExportReport extends Vue {
  private data: any = {}
  // private reportDate: any = 'Spe.2021'

  private loaded: boolean = false

  private getDetail(studentId, periodId): void {
    GradeController.getMonthlyReport(studentId, periodId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => console.log(err))
      .finally(() => (this.loaded = true))
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    const { studentId, periodId } = to.params
    this.getDetail(studentId, periodId)
  }
}
